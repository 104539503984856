window.TNCMS = window.TNCMS || {};
window.TNCMS.Access = TNCMS.Access || [];
window.__tnt || (window.__tnt = {});
window.__tnt.subscription || (__tnt.subscription = {});
window.__tnt.subscription.api || (__tnt.subscription.api = {});
window.__tnt.subscription.bp || (__tnt.subscription.bp = []); // before promise scripts, take no variables, return nothing
window.__tnt.subscription.ap || (__tnt.subscription.ap = []); // after promise scripts, takes oResp, and aValues. return modified oResp
window.__tnt.subscription.a || (__tnt.subscription.a = []);  // access granted scripts, takes oResp
window.__tnt.subscription.d || (__tnt.subscription.d = []);  // access denied scripts, takes oResp
window.__tnt.subscription.access || (__tnt.subscription.access = []);
window.__tnt.subscription.access.output || (__tnt.subscription.access.output = {});
window.__tnt.subscription.access.promises || (window.__tnt.subscription.access.promises = []);
window.__tnt.subscription.access.promisesStageTwo || (window.__tnt.subscription.access.promisesStageTwo = []);
window.__tnt.subscription.access.manifest || (window.__tnt.subscription.access.manifest = {
    denied: {
        promise: null,
        resolve: function(){ }
    }, 
    granted: {
        promise: null,
        resolve: function(){ }
    }
});
window.__tnt.subscription.offers || (__tnt.subscription.offers = {});

__tnt.subscription.access.showLoginModal = false;
__tnt.subscription.access.showPurchaseModal = false;
__tnt.subscription.access.checkedAccess = false;

__tnt.user.firstLogin = false;
__tnt.user.loginSuccess = false;
__tnt.user.purchasedAccess = false;

__tnt.subscription.access.manifest.denied.promise = new Promise(function(resolve, reject) { __tnt.subscription.access.manifest.denied.resolve = resolve });
__tnt.subscription.access.manifest.granted.promise = new Promise(function(resolve, reject) { __tnt.subscription.access.manifest.granted.resolve = resolve });

__tnt.subscription.access.promises.push(__tnt.subscription.access.manifest.denied.promise);
__tnt.subscription.access.promises.push(__tnt.subscription.access.manifest.granted.promise);

window.userStatus = document.createEvent('Event');
    userStatus.initEvent('tnt-user-status', true, true);
    userStatus.version = 3;
    userStatus.hasAccess = false;
    userStatus.accessMethod = 'none';
    userStatus.accessError = null;

__tnt.subscription.offers.transactionDetails = function(modalEl, receiptTab, transactionData) {
    function moneyFormat(locale, money, value){
          return value.toLocaleString(locale, { style: 'currency', currency: money });
    }

    if(modalEl && receiptTab && transactionData && transactionData.transaction_id){
        var templateEl = receiptTab.querySelector('template'),
            outputEl = receiptTab.querySelector('.modal-receipt-output'),
            userNameEl = receiptTab.querySelector('.username'),
            ratePrice = sessionStorage.getItem('__tnt.service.rate.price'),
            rateName = sessionStorage.getItem('__tnt.service.rate.name'),
            total = "",
            startDate = null,
            expireDate = null,
            expireDateString = "",
            receiptElements = [
               {
                  'element': '.transaction-id',
                  'html': transactionData.transaction_id
               }
            ],
            locale = "en-US",
            currency = "USD";

        if(__tnt.user.screenName && userNameEl){
            userNameEl.innerHTML = " " + __tnt.user.screenName;
        }

        if(transactionData.start_date){
            startDate = new Date(transactionData.start_date);
            receiptElements.push({'element': '.service-start', 'html': startDate.toLocaleDateString(locale, {month: 'long', day: 'numeric', year: 'numeric' }) });
        }

        if(transactionData.currency == "CDN"){
            locale = "en-CA";
            currency = "CDN";
        }

        // Populate modal receipt elements
        receiptElements.push({'element': '.service-name', 'html': transactionData.service_name });
        receiptElements.push({'element': '.service-rate', 'html': moneyFormat(locale, currency, Number(transactionData.rate_price)) });

        
        if(transactionData.tax){
            total = Number(transactionData.rate_price) + Number(transactionData.tax);
            receiptElements.push({'element': '.service-tax', 'html': moneyFormat(locale, currency, Number(transactionData.tax)) });
            receiptElements.push({'element': '.service-total', 'html': moneyFormat(locale, currency, total) });
        }

        outputEl.innerHTML = "";

        __tnt.subscription.access.displayReceipt = true;
        __tnt.template(templateEl, outputEl, false, receiptElements, function(t){
            var taxElements = t.querySelectorAll('.tnt-tax-detail');

            if(transactionData.tax && taxElements){
                taxElements.forEach(function(el){
                    el.classList.remove('hidden');
                });
            }
        });
    }
};

/**
 * Access - Handle access / no access
 */
window.TNCMS.Access.push(function() {
    TNCMS.Access.checkAccess(function(oResp) {
        var content = document.getElementById('asset-content'),
            eedition = document.getElementById('eedition-wrapper'),
            i = 0,
            n = 0,
            bpi = 0;

        if(eedition){ content = eedition }

// Set up scripts to run before Promises
        if(__tnt.subscription.bp.length){
            for(bpi=0; bpi<__tnt.subscription.bp.length; bpi++){
                if(typeof(__tnt.subscription.bp[bpi])=="function"){
                    try{
                        __tnt.subscription.bp[bpi](oResp);
                    } catch(error){ console.log(error) }
                }
            }
        }

        // Gift articles
        __tnt.subscription.access.promises.push(new Promise(function(resolve, reject) {
            if (sessionStorage.getItem('tnt.gift.' + document.body.dataset.assetUuid) == 'true') {
                resolve({ has_access: true });
            } else {
                resolve({ has_access: false });
            }
        }));

        var promisePos = __tnt.subscription.access.promises.length;
        __tnt.subscription.access.promises[promisePos] = new Promise(function(resolve, reject){
            function getProduct(){
                if(__tnt.subscription.offers.productData){
                    return __tnt.subscription.offers.productData;
                } else if(sessionStorage.getItem('__tnt.subscription.offers.productData')){
                    __tnt.subscription.offers.productData = JSON.parse(sessionStorage.getItem('__tnt.subscription.offers.productData'));
            
                    return __tnt.subscription.offers.productData;
                }
            }

            // Handle Reciept Display for External Checkout
            var params = new URLSearchParams(document.location.search),
                transactionId = params.get('transaction_id'),
                token = params.get('token'),
                paypalForm = document.getElementById('form-tncms-paypal-purchase'),
                purchaseForm = document.getElementById('form-super-purchase'),
                modalEl = document.getElementById('access-offers-modal'),
                modalTabPurchaseSuccess = document.getElementById('user-modal-tab-purchase-success'),
                purchaseGiftEl = null,
                purchaseStandardEl = null,
                endDate = params.get('expiration'),
                serviceName = null,
                serviceRateName = null,
                servieRatePrice = null,
                taxRate = null,
                date = new Date(),
                receiptViewed = false,
                productData = getProduct(),
                purchaseType = "standard",
                currency = "USD";

            if(endDate != null){ endDate = date.getTime(endDate)
            } else { endDate = "Pending" }
            
            if(sessionStorage.getItem('__tnt.subscription.transaction.viewed.' + transactionId) == 'true'){
                receiptViewed = true;
            }

            if(modalTabPurchaseSuccess){
                purchaseGiftEl = modalTabPurchaseSuccess.querySelector('.purchase-gift');
                purchaseStandardEl = modalTabPurchaseSuccess.querySelector('.purchase-standard');
            }
            if(purchaseGiftEl && purchaseStandardEl){
                if(sessionStorage.getItem('__tnt.subscription.offer.purchaseType')=='gift'){
                    purchaseGiftEl.hidden = false;
                    purchaseStandardEl.hidden = true;
                } else {
                    purchaseGiftEl.hidden = true;
                    purchaseStandardEl.hidden = false;
                }
            }

            if(productData && productData.currency){
                currency = productData.currency;
            }
        
            if(transactionId && !receiptViewed){
                try{
                    serviceName = sessionStorage.getItem('__tnt.service.name');
                    serviceRateName = sessionStorage.getItem('__tnt.service.rate.name');
                    serviceRatePrice = sessionStorage.getItem('__tnt.service.rate.price');
                    taxRate = sessionStorage.getItem('__tnt.service.rate.tax');
                    
                    __tnt.subscription.offers.transactionDetails(modalEl, modalTabPurchaseSuccess, { 
                        transaction_id: transactionId,
                        service_name: serviceName, 
                        service_rate: serviceRateName,
                        rate_price: serviceRatePrice,
                        start_date: date.getTime(),
                        end_date: endDate,
                        tax: taxRate,
                        purchase_type: purchaseType,
                        currency: currency
                    });
                    


                    sessionStorage.setItem('__tnt.subscription.transaction.viewed.' + transactionId, 'true');
                    __tnt.subscription.offers.logOrderCompleted(transactionId);

                    __tnt.user.loginSuccess = true;
                    __tnt.subscription.access.showTab(modalTabPurchaseSuccess, 'access-offers-modal');
            
                } catch(e){ 
                    console.log(e);
                    __tnt.subscription.access.showTab(modalTabPurchaseSuccess, 'access-offers-modal');
                }
         
                $(modalEl).find('.modal').modal();
        
                resolve({
                    has_access:true
                });
            }else{
                resolve({
                    has_access:false
                });
            }
        });

        Promise.all(__tnt.subscription.access.promises).then(function(aValues){
            var o=0,
                p=0,
                pf=0,
                apn=0;

            if(aValues.length){

// Filter Response based on additional data from Promises
                for(pf=0; pf < aValues.length; pf++){
                    if(typeof(aValues[pf]) != 'undefined' && typeof(aValues[pf].has_access) != 'undefined' && aValues[pf].has_access){ oResp.required = false; }
                };

                for(apn=0; apn< __tnt.subscription.ap.length; apn++){
                    try{
                        oResp = __tnt.subscription.ap[apn](oResp, aValues);
                    } catch(error){ console.log(error) }
                };

                for(p=0; p< oResp.access_methods.length; p++){
                    var item = oResp.access_methods[p];

                    if(typeof(item.response)!="undefined" && typeof(item.response.promise_position)=="number"){
                        // update response data with data from async methods run by custom javascript access method.
                        if(aValues[item.response.promise_position].has_access){ oResp.required = false; }
                        oResp.access_methods[p].response = aValues[item.response.promise_position].data; 
                    }
                };
            }

            var promisePos = __tnt.subscription.access.promisesStageTwo.length;
            __tnt.subscription.access.promisesStageTwo[promisePos] = new Promise(function(resolve, reject){
                resolve();
            });

            Promise.all(__tnt.subscription.access.promisesStageTwo).then(function(aValues){
// Filter Response based on additional data from Promises
                if(aValues.length){
                    for(pf=0; pf < aValues.length; pf++){
                        if(typeof(aValues[pf]) != 'undefined' && typeof(aValues[pf].has_access) != 'undefined' && aValues[pf].has_access){ oResp.required = false; }
                    };
                }

// Access Granted
                if (!oResp.required) {
                    for(i=0; i<__tnt.subscription.a.length; i++){
                        try{
                            __tnt.subscription.a[i](oResp);
                        } catch(error){ console.log(error) }
                    }

                    if(content){
                        content.hidden = false;
                    }

                    userStatus.hasAccess = true;

// Access Denied
                }else{
                    for(n=0; n<__tnt.subscription.d.length; n++){
                        try{
                            __tnt.subscription.d[n](oResp);
                        } catch(error){ console.log(error) }
                    }
                }

                if(__tnt.subscription.access.status){
                    __tnt.subscription.access.status.trigger(userStatus);
                }
                __tnt.subscription.access.checkedAccess = true;

                // Modal elements
                var modalEl = document.getElementById('service-promo-modal'),
                    offersModalEl = document.getElementById('access-offers-modal'),
                    wallBehavior = oResp.access_rule.wall_behavior;

                if(offersModalEl) {
                    modalEl = offersModalEl;
                }

            // Create modal and close behavior
                if(modalEl){
                    $(modalEl.querySelector('.modal'))
                        .modal({show: false})
                        .on('hide.bs.modal', function() {
                            if(!__tnt.user.loginSuccess &&  wallBehavior != "nag"){
                            // redirect to root when nag mode is not in use
                                var destination = __tnt.scrubUrl(oResp.access_rule.dismiss_url);
                                if (!destination) {
                                    destination = __tnt.scrubUrl('/');
                                } 
                                if (location.pathname != destination) document.location.href = destination;
                            }else if(wallBehavior == "nag"){
                            // in nag mode do less destructive stuff
                                var assetContent = document.getElementById('asset-content'),
                                    subscriberPremium = null,
                                    previewContent = null;

                                if(assetContent){
                                    subscriberPremium = assetContent.querySelector('.asset-content.subscriber-premium');

                                    if(subscriberPremium){
                                        previewContent = subscriberPremium.querySelectorAll('.subscriber-preview');

                                        if(subscriberPremium.querySelector('.encrypted-content')){
                                            previewContent[previewContent.length-1].classList.add('prompt-additional-content');
                                        }
                                    }
                                }
                            }
                    });
                }

                if(wallBehavior == 'nag' && content){
                    content.hidden = false;
                }
            });
            
        }, function(oResp) {
            console.log('failed: ', oResp);
        });
    });

});

/**
 * Access - Get Endpoint URL
 */
__tnt.subscription.access.endpointUrl = function(){
    var modalEl = document.getElementById('service-promo-modal'),
        ajaxUrl = "";

    if(modalEl && modalEl.getAttribute('data-endpoint')){
        ajaxUrl = __tnt.scrubUrl(modalEl.getAttribute('data-endpoint'));
    }
    if(window.localStorage){
        try{
            if(localStorage.getItem('tnt:access:rule')){
                ajaxUrl += '&accessRule=' + localStorage.getItem('tnt:access:rule');
            }
        } catch(e){ }
    }

    return ajaxUrl;
}

document.addEventListener("DOMContentLoaded", function(event) {
    if (typeof userStatus.hasAccess === 'undefined' || userStatus.hasAccess == false) { 
        var content = document.getElementById('asset-content'),
            wallOutput = document.createElement('div'),
            eedition = document.getElementById('eedition-wrapper');
        
        // initialization
        // hide content
        if(eedition){
            content = eedition;
            content.hidden = true;
        }
    }
});
